.product-addons-modal {
   width: 80%;
   height: 80%;

   padding: 1.5rem 2rem;

   background-color: #FFFFFF;

   display: flex;
   flex-direction: column;
   row-gap: 2rem;

   border-radius: 2rem;

   box-shadow: var(--hud-shadow);

   position: relative;
}

@media only screen and (min-width: 768px) {
   .product-addons-modal {
      width: 40%;
      max-width: 60rem;

      padding: 1.5rem 6rem;
   }
}

.product-addons-close {
   position: absolute;
   top: 1rem;
   right: 1.5rem;

   font-size: 1.4rem;
   line-height: 80%;

   display: flex;
   align-items: center;
   justify-content: center;

   width: 2.5rem;
   height: 2.5rem;

   border-radius: 50%;

   color: #FFFFFF;
   background-color: var(--secondary-color);

   cursor: pointer;

   transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
   .product-addons-close {
      top: 1.5rem;
      right: 2rem;
   
      font-size: 2rem;
   
      width: 3rem;
      height: 3rem;
   }
}

.product-addons-close:hover {
   background-color: var(--dark-secondary-color);
}

.product-addons-title {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;
}

.product-addons-product {
   font-size: 1.4rem;
   font-weight: 300;
   text-align: center;

   color: var(--main-color);

   margin-top: -1rem;
}

.product-addons-select {
   display: flex;
   flex-direction: column;
}

.product-addons-select > label {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   margin-left: 0.5rem;
   margin-bottom: 0.3rem;
}

.product-addons-select > select {
   padding: 0.5rem 1rem;

   background-color: transparent;

   border: 0.15rem solid var(--dark-main-color);
   border-radius: 0.5rem;

   cursor: pointer;
}

.product-addons-results {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   overflow: hidden;

   flex-grow: 1;
}

.product-addons-results > p {
   line-height: 100%;

   padding-bottom: 0.5rem;
   border-bottom: 0.15rem solid var(--dark-main-color);
}

.product-addons-elements {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   overflow-y: auto;

   flex-grow: 1;
}

.product-addons-elements::-webkit-scrollbar {
   width: 0.3rem;
}

@media only screen and (min-width: 768px) {
   .product-addons-elements::-webkit-scrollbar {
      width: 0.7rem;
   }
}

/* Handle */
.product-addons-elements::-webkit-scrollbar-thumb {
   background-color: var(--light-grey);

   border-radius: 1rem;
}

/* Handle on hover */
.product-addons-elements::-webkit-scrollbar-thumb:hover {
   background-color: var(--main-color);
}

.product-addons-element {
   display: flex;
   align-items: center;
   gap: 0.5rem;

   padding: 0.3rem;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 0.5rem;
}

.product-addons-img {
   width: 35%;

   display: flex;

   flex-shrink: 0;

   overflow: hidden;

   border-radius: 0.5rem;
}

.product-addons-img > img {
   width: 100%;

   aspect-ratio:  3 / 2;
   object-fit: contain;
}

.product-addons-data {
   display: flex;
   flex-direction: column;

   width: auto;

   flex-grow: 1;
}

.product-addons-data > p {
   font-size: 1.4rem;
   line-height: 100%;

   margin-bottom: 0.5rem;
}

.product-addons-row {
   display: flex;
}

.product-addons-row > p {
   font-size: 1.2rem;

   flex-shrink: 0;
}

.product-addons-row > p:first-child {
   font-weight: 300;

   width: 55%;
}

.product-addons-row > p:last-child {
   width: 45%;
}

.product-addons-elements-disabled {
   height: 100%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 1rem;

   color: var(--main-color);

   text-align: center;
}

@media only screen and (min-width: 768px) {
   .product-addons-elements-disabled {
      flex-direction: row;

      text-align: start;
   }
}

.product-addons-elements-disabled > svg {
   width: 8rem;

   flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
   .product-addons-elements-disabled > svg {
      width: 6rem;
   }
}

.product-addons-elements-disabled > p {
   flex-shrink: 1;
}

.product-addons-elements-empty {
   height: 100%;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 1rem;

   color: var(--secondary-color);

   text-align: center;
}

@media only screen and (min-width: 768px) {
   .product-addons-elements-empty {
      flex-direction: row;

      text-align: start;
   }
}

.product-addons-elements-empty > svg {
   width: 8rem;
   font-size: 8rem;

   flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
   .product-addons-elements-empty > svg {
      width: 6rem;
      font-size: 6rem;
   }
}

.product-addons-elements-empty > p {
   flex-shrink: 1;
}