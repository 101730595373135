.brand-header {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .brand-header {
      width: 50%;
      max-width: 65rem;

      margin: 0 auto;
   }
}

.brand-header-img {
   width: 50%;

   display: flex;

   margin: 0 auto;
}

@media only screen and (min-width: 768px) {
   .brand-header-img {
      width: 60%;
   }
}

.brand-header-img > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.brand-nav {
   width: 100%;
   
   display: flex;
   align-items: center;
}

.brand-nav-element {
   color: var(--dark-main-color);

   border: 0.2rem solid var(--dark-main-color);

   width: 50%;

   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   display: flex;
   justify-content: center;
   align-items: center;

   padding: 0.7rem 0;
   
   transition: color 0.1s linear, background-color 0.1s linear;
}

.brand-nav-element:not(.active) {
   cursor: pointer;
}

.brand-nav-element:first-child {
   border-right: 0.1rem solid var(--dark-main-color);
   border-top-left-radius: 1rem;
   border-bottom-left-radius: 1rem;
}

.brand-nav-element:last-child {
   border-left: 0.1rem solid var(--dark-main-color);
   border-top-right-radius: 1rem;
   border-bottom-right-radius: 1rem;
}

.brand-nav-element.active {
   color: #FFFFFF;
   background-color: var(--dark-main-color);
}

.brand-description {
   white-space: pre-wrap;
}

.brand-description-title {
   text-align: center;
   font-size: 4rem;
   margin: 2rem 0;
}

.brand-description-link {
   display: block;
   margin-top: 2rem;
   margin-left: auto;
   padding: 1rem;
   border-radius: .5rem;
   background-color: var(--main-color);
   color: #fff;
   transition: ease 200ms background;
   cursor: pointer;

   &:hover {
      background-color: var(--dark-main-color);
   }
}