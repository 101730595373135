.generated {
   width: 100%;

   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .generated {
      width: 80%;

      margin: 0 auto;
   }
}

.generated-title {
   font-weight: 500;
   text-align: center;
}

.generated-number {
   font-size: 2rem;
   line-height: 100%;
   text-align: center;

   color: var(--light-grey);

   margin-top: 1rem;
}

.generated-number > a {
   color: var(--main-color);
}

.generated-boxes {
   width: 100%;
   
   margin-top: 3rem;

   display: flex;
   flex-direction: column;
   row-gap: 3rem;
}

.generated-submit-container {
   position: relative;
   height: 100%;
}

.generated-details-submit {
   position: relative;
   display: flex;
   flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
   .generated-details-submit {
      position: relative;
      display: grid;
      gap: 2rem;
      grid-template-columns: 3fr 1fr;
   }
   .generated-boxes {
      flex-direction: row;
      justify-content: space-between;
   }
}

.generated-box {
   width: 100%;
   min-height: 12rem;

   padding: 1.5rem 2rem;

   background-color: var(--soft-main-color);

   border: var(--input-border);
   border-radius: 2rem;

   display: flex;
   flex-direction: column;
   align-items: center;
}

@media only screen and (min-width: 768px) {
   .generated-box {
      width: 27%;
      min-height: 14rem;
   }
}

.generated-box-title {
   font-size: 1.6rem;
   line-height: 100%;
   text-align: center;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .generated-box-title {
      font-size: 1.4rem;
   }
}

.generated-box-main {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: center;

   color: var(--grey);

   margin: auto 0 0.50rem;
}

@media only screen and (min-width: 768px) {
   .generated-box-main {
      font-size: 1.6rem;
   }
}

.generated-box-secondary {
   font-size: 1.8rem;
   line-height: 100%;
   text-align: center;
   
   color: #94afc0;

   margin: 0.50rem 0 auto;
}

@media only screen and (min-width: 768px) {
   .generated-box-secondary {
      font-size: 1.6rem;
   }
}

.generated-box-secondary.important {
   color: var(--main-color);
}

.generated-box-body {
   width: 80%;

   display: flex;

   margin: auto 0;
}

@media only screen and (min-width: 768px) {
   .generated-box-body {
      width: 100%;
      max-width: 20rem;
   }
}

.generated-box-body > img { 
   width: 100%;
}

.generated-payment-data {
   width: 100%;

   margin-top: 3rem;
   padding: 1.5rem 2rem;

   background-color: var(--soft-main-color);

   border: var(--input-border);
   border-radius: 2rem;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.generated-payment-data > p {
   font-weight: 400;

   color: var(--light-grey);
}

.generated-payment-data > p.centered {
   text-align: center;
}

.generated-payment-data > p.margin-top {
   margin-top: 1.5rem;
}

.generated-payment-data > p > span {
   font-weight: 500;
   color: var(--main-color);
}

.generated-payment-data > select {
   margin: 1.5rem auto 0;
   width: 100%;

   text-align: center;

   color: var(--dark-main-color);

   border: 0.1rem solid var(--main-color);
   border-radius: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .generated-payment-data > select {
      width: 60%;
   }
}

.generated-products {
   width: 100%;

   margin-top: 3rem;

   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .generated-products {
      row-gap: 1rem;
   }
}

.generated-data {
   width: 100%;

   padding: 1.5rem 2rem;

   background-color: var(--soft-main-color);

   border: var(--input-border);
   border-radius: 2rem;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .generated-data {
      width: unset;
      min-width: 40rem;

      flex-shrink: 0;
   }
}

.generated-data-title {
   font-size: 2rem;
   font-weight: 500;
   line-height: 100%;

   color: var(--dark-main-color);

   margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
   .generated-data-title {
      font-size: 1.8rem;
   }
}

.generated-data-row {
   width: 100%;

   display: flex;
   justify-content: space-between;
   align-items: flex-start;
}

.generated-data-key {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-align: start;
}

@media only screen and (min-width: 768px) {
   .generated-data-key {
      font-size: 1.6rem;
   }
}

.generated-data-value {
   font-size: 1.8rem;
   line-height: 100%;
   text-align: end;

   color: var(--light-grey);
}

@media only screen and (min-width: 768px) {
   .generated-data-value {
      font-size: 1.6rem;
   }
}

.generated-submit {
   position: static;
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 2rem;
   flex-grow: 1;
   margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
   .generated-submit {
      position: sticky;
      top: 15rem;
      align-items: flex-end;
      row-gap: 1rem;

      flex-grow: 1;
   }
}

.generated-payment-buttons {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.generated-amount {
   font-size: 1.6rem;
   line-height: 100%;

   width: 100%;

   display: flex;
   justify-content: space-between;
}

.generated-amount.subtotal {
   padding-top: 2rem;
   border-top: var(--input-border);
}

.generated-amount > span {
   font-weight: 500;
}

.generated-total {
   font-size: 2.4rem;
   line-height: 100%;

   color: var(--dark-main-color);

   padding-top: 2rem;

   border-top: var(--input-border);

   width: 100%;

   display: flex;
   justify-content: space-between;
}

.generated-subtotal {
   font-size: 2.4rem;
   line-height: 100%;

   color: var(--dark-green);

   padding-top: .25rem;

   /* border-top: var(--input-border); */

   width: 90%;

   display: flex;
   justify-content: space-between;
}

@media only screen and (min-width: 768px) {
   .generated-total, .generated-subtotal {
      font-size: 1.9rem;
      width: 100%;
   }
}

.generated-total > span {
   font-weight: 500;

   color: var(--main-color);
}

.generated-button {
   padding: 0.7rem 1rem;

   display: flex;
   align-items: center;

   color: #FFFFFF;
   background-color: var(--main-color);

   border-radius: 0.5rem;

   transition: background-color 0.2s linear;

   gap: .4rem;

   max-height: 35px;

   margin-top: 18px;
}

@media only screen and (min-width: 768px) {
   .generated-button {
      cursor: pointer;

      max-height: 40px;

      width: 100%;
}
}

.generated-button:hover {
   background-color: var(--dark-main-color);
}

.generated-button > p {
   font-size: 2rem;
   line-height: 100%;
   font-weight: 500;
   letter-spacing: 0.1rem;
   text-align: center;
   text-transform: uppercase;

   flex-grow: 1;
}

@media only screen and (min-width: 768px) {
   .generated-button > p {
      font-size: 1.6rem;
   }
}

.generated-button > svg {
   font-size: 2.4rem;
}

@media only screen and (min-width: 768px) {
   .generated-button > svg {
      font-size: 2rem;
   }
}