.product-info {
   display: grid;
   grid-template-columns: repeat(6, 1fr);
   grid-template-areas: "tags tags tags tags tags tags"
                        "imgs imgs imgs atts atts atts"
                        "ribb ribb ribb ribb ribb ribb"
                        "data data data data data data"
                        "btns btns btns btns btns btns";

   width: 100%;

   border: 0.1rem solid var(--input-grey);
   border-radius: 1rem;

   background-color: #FFFFFF;
}

@media only screen and (min-width: 992px) {
   .product-info {
      grid-template-columns: repeat(5, 1fr);
      grid-template-areas: "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs btns btns btns";
      column-gap: 2rem;

      max-width: 130rem;
      margin: 0 auto;

      padding: 0 0 2.5rem;

      overflow: hidden;

      position: relative;
   }

   .product-info:has(.product-offer-tags-container) {
      grid-template-areas: "imgs imgs atts tags tags"
                           "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs btns btns btns";
   }

   .product-info:has(.product-contact-container) {
      grid-template-areas: "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs atts btns btns";
   }

   .product-info:has(.product-offer-tags-container):has(.product-contact-container) {
      grid-template-areas: "imgs imgs atts tags tags"
                           "imgs imgs atts data data"
                           "imgs imgs atts data data"
                           "imgs imgs atts btns btns";
   }
}

.product-info-loading {
   padding: 20rem 0;
}

@import url('./product-tags.css');

.product-sku {
   font-size: 1.4rem;
   font-weight: 300;

   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .product-sku {
      font-size: 2rem;
   }
}

.product-ribbon {
   grid-area: ribb;

   color: #FFFFFF;
   background-color: var(--main-color);

   padding: 0.5rem 1rem;

   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;

   display: flex;
   align-items: center;
   justify-content: center;
}

@media only screen and (min-width: 768px) {
   .product-ribbon {
      padding: 1rem 1.5rem;

      border-top-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border-top-right-radius: 0;

      position: absolute;
      top: -0.1rem;
      left: -0.1rem;
   }
}

@keyframes card-ribbon-info {
   0% {
      scale: 1;
   }

   50% {
      scale: 1.05;
   }

   100% {
      scale: 1;
   }
}

.producto-ribbon-text {
   font-size: 1.8rem;
   font-weight: 700;
   text-align: center;
   line-height: 100%;

   animation-name: card-ribbon-info;
   animation-duration: 1s;
   animation-iteration-count: infinite;
}

.product-attributes {
   display: flex;

   width: 100%;
}

.product-attributes-list {
   grid-area: atts;

   width: 100%;
   
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;

   padding: 0 1rem 0 0;
}

@media only screen and (min-width: 768px) {
   .product-attributes-list {
      padding: 2.5rem 2.5rem 0 0;
   }

   .product-offer-tags-container ~ .product-attributes-list {
      padding: 0 2.5rem 0 0;
   }
}

@media only screen and (min-width: 992px) {
   .product-attributes-list {
      padding: 2.5rem 0 0!important;
   }
}

.product-img {
   grid-area: imgs;
   
   width: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.product-img-main {
   width: 100%;
   max-width: 40rem;

   margin: 0 auto;

   display: flex;

   flex-grow: 1;
}

.product-img-main > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-img-secondary {
   padding: 0 7.5%;
}

.product-img-list {
   width: 100%;
   display: flex;
   column-gap: 1rem;

   padding-bottom: 0.2rem;

   overflow-x: auto;
}

.product-img-list::-webkit-scrollbar {
   height: 0.7rem;
}

/* Handle */
.product-img-list::-webkit-scrollbar-thumb {
   background-color: var(--light-grey);

   border-radius: 1rem;
}

/* Handle on hover */
.product-img-list::-webkit-scrollbar-thumb:hover {
   background-color: var(--main-color);
}

.product-img-list-element {
   display: flex;

   flex-shrink: 0;

   width: calc(100% / 3.5);

   border: 0.1rem solid var(--input-grey);

   cursor: pointer;

   opacity: 0.5;

   position: relative;

   transition: border-color 0.2s linear, opacity 0.2s linear;
}

.product-img-list-element.current {
   border-color: var(--light-main-color);

   opacity: 1;
}

.product-img-list-element > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-brand-img {
   width: 70%;

   margin-bottom: 1rem;

   align-self: center;

   display: flex;
   justify-content: center;
}

@media only screen and (min-width: 768px) {
   .product-brand-img {
      width: 100%;
   }
}

.product-brand-img > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.product-data {
   grid-area: data;

   display: flex;
   flex-direction: column;

   width: 100%;

   background-color: var(--dark-main-color);

   padding: 1rem 0;

   border-radius: 0.5rem;

   position: relative;
}

@media only screen and (min-width: 768px) {
   .product-data {
      padding: 2.5rem;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      min-height: 20rem;

      /* max-width: 34rem; */

      /* margin-left: auto; */
   }
}

@media only screen and (max-width: 767px) {
   .product-ribbon ~ .product-data {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
   }
}

.product-title {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;
   
   margin-bottom: 2rem;
   padding: 0 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   color: #FFFFFF;
}


@media only screen and (min-width: 768px) {
   .product-title {
      font-size: 2.4rem;

      padding: 0;
      margin-bottom: 1rem;
   }
}

.product-title > h1 {
   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;
   text-transform: uppercase;

   margin: 0;
}

@media only screen and (min-width: 768px) {
   .product-title > h1 {
      font-size: 2.4rem;
   }
}


.product-data-row {
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   grid-template-areas: "data data data perk perk"
                        "spec spec spec spec spec";

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .product-data-row {
      grid-template-areas: "data data data data perk"
                           "spec spec spec spec spec";
      row-gap: 1rem;
   }
}

@media only screen and (min-width: 992px) {
   .product-data-row {
      flex-grow: 1;
   }
}

.product-perks {
   grid-area: perk;

   width: 100%;

   padding-right: 1rem;
}

@media only screen and (min-width: 768px) {
   .product-perks {
      padding-right: 0;
   }
}

@media only screen and (min-width: 992px) {
   .product-perks {
      width: unset;

      position: relative;
      right: -2.5rem;
   }
}

.product-perks-container {
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   row-gap: 1rem;
}

.product-perk {
   text-align: center;

   width: 100%;

   padding: 0.5rem 1rem;

   border-radius: 0.3rem;

   color: #FFFFFF;
   background-color: var(--main-color);

   display: flex;
   align-items: center;
   column-gap: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .product-perk {
      width: 100%;

      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
   }
}

.product-perk svg {
   font-size: 1.8rem;
   width: 1.8rem;
}

.product-perk p {
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;
}

@media only screen and (min-width: 768px) {
   .product-perk p {
      font-size: 1.4rem;
      text-align: start;

      flex-grow: 1;
   }
}

.product-billing {
   grid-area: data;

   width: 100%;

   color: #FFFFFF;

   padding: 0 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1.5rem;

   font-weight: 300;
}

@media only screen and (min-width: 768px) {
   .product-billing {
      width: 100%;

      padding: 0;
   }
}

.product-price {
   line-height: 100%;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;

   position: relative;
}

@media only screen and (min-width: 768px) {
   .product-price {
      font-size: 2rem;
      font-weight: 500;

      row-gap: 0.5rem;
   }
}

.product-price-amount {
   font-size: 1.4rem;

   color: #8D8D8D;
}

@media only screen and (min-width: 768px) {
   .product-price-amount {
      font-size: 2.4rem;
      font-weight: 400;

      color: #8D8D8D;
   }
}

.product-price-amount.offer {
   font-size: 2rem;
   font-weight: 500;
      
   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .product-price-amount.offer {
      font-size: 3.2rem;
      font-weight: 600;
   }
}

.product-price-amount.offer-4x3 {
   font-size: 2.4rem;
   font-weight: 500;

   color: #FFFFFF;

   color: var(--light-main-color);
}

@media only screen and (min-width: 768px) {
   .product-price-amount.offer-4x3 {
      font-size: 4rem;
      line-height: 90%;
      font-weight: 600;
   }
}

.product-price-amount > span {
   font-size: 1rem;
}

@media only screen and (min-width: 768px) {
   .product-price-amount > span {
      font-size: 1.4rem;
   }
}

.product-price-amount.offer > span {
   font-size: 1.2rem;
}

@media only screen and (min-width: 768px) {
   .product-price-amount.offer > span {
      font-size: 1.6rem;
   }
}

.product-credit {
   grid-area: spec;

   margin: 1rem 1rem 0;

   display: flex;
   flex-direction: row;
   justify-content: center;
   column-gap: 2rem;
   
   font-size: 1.4rem;
}

@media only screen and (min-width: 768px) {
   .product-credit {
      margin: 1rem 0 0;

      font-size: 1.8rem;
      font-weight: 500;
   }
}

@media only screen and (min-width: 992px) {
   .product-credit {
      margin: auto 0;

      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
   }
}

@media only screen and (min-width: 1200px) {
   .product-credit {
      margin: auto 0;

      flex-direction: row;
      align-items: center;
      row-gap: 1rem;
   }
}

.product-credit-title {
   line-height: 100%;

   color: var(--yellow);
}

.product-credit-group {
   width: fit-content;

   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: inherit;
   column-gap: 2rem;

   font-size: inherit;

   padding: 0.5rem;

   color: #FFFFFF;
   background-color: var(--soft-dark-main-color);

   border-radius: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .product-credit-group {
      width: fit-content;

      padding: 1rem;
   }
}

.product-credit-row {
   display: flex;
   column-gap: 2rem;
   align-items: center;
}

.product-credit-details {
   display: flex;
   flex-direction: column;
   align-items: center;

   font-size: 1.4rem;
   font-weight: 500;

   flex-shrink: 0;
}

@media only screen and (min-width: 768px) {
   .product-credit-details {
      font-size: 1.6rem;
   }
}

.product-credit-details span {
   font-size: 1.2rem;
   font-weight: 300;
}

@media only screen and (min-width: 768px) {
   .product-credit-details span {
      font-size: 1.4rem;
   }
}

.product-credit-price {
   font-size: 2rem;
   font-weight: 600;
   line-height: 100%;
   text-align: center;

   flex-grow: 1;

   color: var(--soft-main-color);
}

@media only screen and (min-width: 768px) {
   .product-credit-price {
      font-size: 3.2rem;
   }
}

.product-credit-clause {
   width: fit-content;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 0.5rem;

   font-weight: 300;

   color: #FFFFFF;
}

@media only screen and (min-width: 768px) {
   .product-credit-clause {
      flex-direction: row;
      row-gap: 1rem;
      column-gap: 1rem;

      font-size: 1.8rem;
   }
}

@media only screen and (min-width: 1200px) {
   .product-credit-clause {
      flex-direction: column;
   }
}

.product-credit-clause-img {
   width: 10rem;
}

@media only screen and (min-width: 768px) {
   .product-credit-clause-img {
      width: 12rem;
   }
}

.product-credit-clause-img > img {
   width: 100%;
}



/* Distribución dentro del grid */
.product-grid-btn {
   grid-area: btns;
}