.auth-test-modal {
  background-color: #fff;
  padding: 2rem;
  width: 350px;
  height: 300px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.auth-test-modal-title {
  text-align: center;
}

.auth-test-modal-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.auth-test-modal-footer {
  text-align: center;
  font-size: 1.3rem;
}

.auth-test-modal-footer > a {
  color: var(--main-color);
}

.auth-test-modal-submit {
  border-radius: .7rem;
  background-color: var(--main-color);
  padding: .5rem 1rem;
  color: #fff;
  cursor: pointer;
  transition: ease 120ms background;
}
.auth-test-modal-submit:hover {
  background-color: var(--light-main-color);
}