.self-management-banner {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

.self-management-banner > .logo {
  height: 120px;
  min-height: 80px;
}

.self-management-banner > .title {
  width: fit-content;
  border-left: 1px solid #aaa;
  margin: auto 0;
  padding: 2rem;
}

.self-management {
  width: 80%;
  min-width: 360px;
  margin: 3rem auto;
  max-width: 900px;
  position: relative;
  margin-bottom: 15rem;

  .subtitle {
    text-align: center;
    font-size: 2rem;
    margin: 1rem;
  }

  .steps {
    display: flex;
    gap: 1rem;
    max-height: fit-content;
    
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1rem;
      max-width: 280px;
      justify-content: space-between;
      text-align: center;
      height: 340px;

      h4 {
        font-size: 3rem;
        font-weight: bold;
      }

      h5 {
        font-size: 1.6rem;
        margin-bottom: .5rem;
      }

      figcaption {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      
      img {
        padding: 1rem;
        height: 150px;
      }
    }
  }
}

.self-management-inputs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.5rem;
}


.self-management-inputs-container > label {
  border: 1px solid #aaa;
  font-weight: bold;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;
  padding-left: 1rem;
  text-wrap: nowrap;
  font-size: 1.8rem;
  background-color: #fff;
}
.self-management-inputs-container > label > * {
  background-color: #ffffff00;
  margin-left: 1rem;
  font-weight: normal;
  min-width: 10ch;
  width: 100%;
}
.self-management-inputs-container > label > button[type='submit'] {
  padding: 1rem;
  display: flex;
  width: fit-content;
  gap: .7rem;
  margin-left: auto;
  background-color: var(--main-color);
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  transition: ease 150ms background;
}
.self-management-inputs-container > label > button[type='submit']:disabled {
  background-color: #aaa;
  cursor: default;
  color: #fff;
}

.self-management-subtitle {
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin: 1rem 0;
  
}

.self-management-results-table-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 1px solid var(--dark-main-color);
  padding: 2rem;
}

.self-management-results-table-title {
  text-align: center;
}

.self-management-results-table {
  border-spacing: 0 10px;
  
}

.self-management-table-details > button {
  margin: auto;
  max-width: 200px;
  background-color: #06ac3e;
  padding: .5rem 1rem;
  text-align: center;
  color: #fff;
  border-radius: 1rem;
  cursor: pointer;
  transition: ease 300ms background;
}
.self-management-table-details > button:hover {
  background-color: #067e2e;
}

.self-management-results-table > tbody > tr > td{
  border-top: 1px solid rgb(196, 196, 196);
  padding-top: 1rem;
}

.self-management-table-data.data {
  grid-area: a;
}
.self-management-table-agenda.agenda{
  grid-area: b;
}
.self-management-table-details.details {
  grid-area: c;
  margin-top: 2rem;
}

.self-management-table-info {
  display: grid;
  grid-template-areas: 
  "a a b"
  "c c c";
  width: 100%;
}
.self-management-table-info > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .3rem;
}

.self-management-table-info {
  padding: 2rem;
  border-radius: 1rem;
  transition: ease 150ms background, color;
  cursor: pointer;
}
.self-management-table-info:hover {
  background-color: #0d00ff33;
}
.self-management-table-info.selected{
  background-color: var(--dark-main-color);
  color: #fff;
}



.self-management-table-detailed {
  background-color: #fff;
  border-radius: 1rem;
  padding: .5rem;
  color: var(--dark-main-color);
}
.self-management-table-detailed.footer {
  color: #111;
  border-top: 2px solid #aaa;
  border-radius: 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.self-management-table-detailed.loading {
  text-align: center;
}

.self-management-table-detailed > table {
  width: 100%;
}
.self-management-table-detailed > table > thead > tr> th {
  border-bottom: 1px solid #aaa;
}

.self-management-table-detailed > table > thead > tr > th,
.self-management-table-detailed > table > tbody > tr > td {
  padding: .3rem 1rem;
  text-align: left;
}

.anchor-hidden {
  width: 100%;
}

.self-management-form-bottom {
  position: fixed;
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--dark-main-color);
  padding: 3rem;
  border-top: 1px solid #aaa;
  min-height: 110px;
}

.self-management-form-bottom > .self-management-inputs-container {
  display: flex;
  flex-direction: row;
  width: auto;
}

.self-management-form-bottom > .self-management-inputs-container > label {
  border: 1px solid #aaa;
  font-weight: bold;
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  align-items: center;
  padding-left: 1rem;
  text-wrap: nowrap;
  font-size: 1.8rem;
  min-height: 45px;
}

.print-pdf-btn {
  padding: 1rem 3rem;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 1rem;
  margin-left: auto;
  font-size: 2rem;
  transition: 150ms ease background;
  cursor: pointer;
}
.print-pdf-btn:disabled {
  background-color: #aaa;
  cursor: default;
}

.self-management-reset-values {
  padding: .5rem .7rem;
  border-radius: 1rem;
  background-color: var(--runflat-tag-color);
  color: #fff;
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
  opacity: 1;
  transition: ease 150ms opacity;
}

.self-management-reset-values.hidden {
  opacity: 0.4;
}

.self-management-table-data > span {
  font-size: 1.8rem;
  min-width: 50ch;
}

.self-management-table-agenda {
  border: 2px solid var(--dark-main-color);
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  align-items: center;
  min-height: 85px;
  color: #111;
  margin-left: 1rem;
}

.agenda-title {
  font-weight: bold;
  color: var(--main-color);
}
.agenda-title.none {
  color: var(--runflat-tag-color);
}

.no-data {
  font-style: italic;
  font-size: 1.7rem;
  text-align: center;
}

/* MARK: NOTA DE VENTA DETALLADA */

.self-management-detailed-nv-container {
  border-radius: 1rem;
  border: 1px solid var(--dark-main-color);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--dark-main-color);
  color: #fff;
}

.self-management-detailed-nv {
  display: flex;
  justify-content: space-between;
}

.self-management-detailed-nv-title {
  text-align: center;
  padding: 0 2rem;
  padding: 1rem;
  border-bottom: 1px solid #aaa;
}

.self-management-detailed-nv-info {
  font-size: 1.8rem;
  display: flex;
  flex-direction: column;
  gap: .3rem;
  padding-right: 1rem;
  justify-content: center;
}

.self-management-detailed-nv-agenda {
  color: var(--dark-main-color);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.self-management-detailed-nv-agenda-data {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: .3rem;
}

.self-management-detailed-nv-agenda-data > h4 {
  color: var(--main-color);
}

.self-management-detailed-nv-agenda-data.none{
  color: var(--runflat-tag-color);
}

.self-management-loading-patente {
  margin: 0 auto;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

/* MARK: SELECT-COMUNA */

.missing-field {
  animation: flash 1.5s infinite;
}

.self-management-select-comuna {
  min-width: 300px;
}
.self-management-select-comuna > select {
  width: 100%;
  text-align: left;
}


/* MARK: STEPS */


@keyframes flash {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #faf43e;
  }
  100% {
    background-color: #ffffff;
  }
}