.maintenance_container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: auto;
  /* border: 5px solid var(--main-color); */
  border-radius: 1rem;
  margin-top: 5rem;
  padding: 4rem;
  flex-wrap: wrap;
  text-align: center;
  background-color: var(--dark-main-color);
  box-shadow: 2px 2px 6px #2222226a;
  color: #fff;
  gap: 5rem;
}

.maintenance_message {
  font-size: 35px;
  margin-top: 5rem;
}
.maintenance_message > h4 {
  font-size: 25px;
  margin-top: 1rem;
  color: #ccc;
  font-weight: 100;
}

.chn_logo {
  max-width: 150px;
  height: auto;
  border-radius: 1rem;
  transition: ease 200ms background;
}
.chn_logo:hover {
  background-color: rgba(25, 0, 255, 0.135);
}

.chileneumaticos_logo_container {
  width: 100%;
  height: auto;
  max-width: 400px;
  padding: 1rem;
}

.chileneumaticos_logo {
  width: 100%;
  height: auto;
}

.redirect_container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}