.partial-payment-modal { 
  width: 90%;
  padding: 1.5rem 2rem;
  max-width: 900px;
  max-height: 90%;
  overflow-y: auto;

  background-color: #FFFFFF;

  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  border-radius: 2rem;

  box-shadow: var(--hud-shadow);

  position: relative;
}

.partial-payment-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;

  font-size: 1.4rem;
  line-height: 80%;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;

  color: #FFFFFF;
  background-color: var(--secondary-color);

  cursor: pointer;

  transition: background-color 0.2s ease;
}

@media only screen and (min-width: 768px) {
  .partial-payment-close {
     top: 1.5rem;
     right: 2rem;
  
     font-size: 2rem;
  
     width: 3rem;
     height: 3rem;
  }
}

.partial-payment-title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 100%;

  margin-bottom: 1rem;

  color: var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
  .partial-payment-title {
     text-align: center;
  }
}

.partial-payment-info {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.partial-payment-order-num {
  line-height: 100%;
  text-align: center;

  align-self: center;
}

.partial-payment-header {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 100%;
  text-align: center;

  padding-top: 1rem;

  color: var(--main-color);

  border-top: var(--input-border);
}

.partial-payment-form {
  padding-top: 1rem;
  border: 1px solid var(--main-color);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.partial-payment-form-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 300px;
}

.abono-payment-amount-input {
  margin-top: 1rem;
  width: 100%;
}