.page-spinner-container {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   min-height: 100vh;

   display: flex;
   align-items: center;
   justify-content: center;

   z-index: var(--spinner-index);

   background-color: #FFFFFF;
}

.fixed-spinner-container {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;

   background-color: rgba(0, 0, 0, 0.50);

   backdrop-filter: blur(0.2rem);

   display: flex;
   align-items: center;
   justify-content: center;

   z-index: var(--spinner-index);
}

.fixed-spinner-container.fixed {
   position: fixed;
}

.element-spinner-container {
   width: 100%;
   height: 100%;

   display: flex;
   align-items: center;
   justify-content: center;
}

.absolute-spinner-container {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   
   display: flex;
   align-items: center;
   justify-content: center;
}

.page-spinner {
   width: 88px;
   height: 88px;
   display: grid;
   animation: spinner-plncf9 5.6s infinite;
}

.page-spinner::before,
.page-spinner::after {
   content: "";
   grid-area: 1/1;
   border: 14.1px solid;
   border-radius: 50%;
   border-color: #001a70 #001a70 #0000 #0000;
   mix-blend-mode: darken;
   animation: spinner-plncf9 1.4s infinite linear;
}

.page-spinner::after {
   border-color: #0000 #0000 #699bf7 #699bf7;
   animation-direction: reverse;
}

.page-spinner.tiny {
   width: 44px;
   height: 44px;
}

.page-spinner.tiny::before,
.page-spinner.tiny::after {
   border-width: calc(14.1px / 2);
}

@keyframes spinner-plncf9 {
   100% {
      transform: rotate(1turn);
   }
}

/* Estilos específicos */

.brand-spinner {
   padding: 29vh 0;
}

.offices-spinner {
   padding: 20rem 0;
}

.order-spinner {
   padding: 15rem 0;
}

.faq-spinner {
   padding: 29vh 0;
}

.tracking-spinner {
   padding: 10rem 0;
}

@media only screen and (min-width: 768px) {
   .generated-spinner {
      padding: 20rem 0;
   }
}