
/* Navigation */

.event-nav {
   width: 100%;
   
   display: flex;
   align-items: center;
}

.event-nav-element {
   color: var(--dark-main-color);

   border: 0.2rem solid var(--dark-main-color);

   width: 50%;

   font-size: 1.8rem;
   font-weight: 500;
   line-height: 100%;

   display: flex;
   justify-content: center;
   align-items: center;

   padding: 0.7rem 0;
   
   transition: color 0.1s linear, background-color 0.1s linear;
}

.event-nav-element:first-child {
   border-right: 0.1rem solid var(--dark-main-color);
   border-top-left-radius: 1rem;
   border-bottom-left-radius: 1rem;
}

.event-nav-element:last-child {
   border-left: 0.1rem solid var(--dark-main-color);
   border-top-right-radius: 1rem;
   border-bottom-right-radius: 1rem;
}

.event-nav-element.active {
   color: #FFFFFF;
   background-color: var(--dark-main-color);
}

/* Container */

.event-section {
   max-width: 130rem;
}

/* Banner */

.event-banner {
   display: flex;

   border-radius: 1.5rem;

   aspect-ratio: 5 / 2;
   object-fit: contain;

   overflow: hidden;

   width: 100%;
}

@media only screen and (min-width: 768px) {
   .event-banner {
      aspect-ratio: 5 / 1;
      object-fit: contain;
   }
}

.event-banner > img {
   width: 100%;
}



/* Intro */

@media only screen and (min-width: 768px) {
   .event-intro {
      font-size: 1.8rem;
      line-height: 140%;
   }
   .event-intro::first-letter {
      margin-left: 3rem;
   }
}



/* Title */

.event-title {
   font-size: 1.8rem;
   font-weight: 500;
   letter-spacing: 0.05rem;
   line-height: 100%;

   color: var(--dark-main-color);

   padding-bottom: 0.5rem;
   margin-bottom: 1rem;

   width: 100%;

   border-bottom: 0.1rem solid var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .event-title {
      font-size: 2.2rem;

      margin-bottom: 3rem;
   }
}



/* Brands */

.event-brands-container {
   overflow: hidden;
}

.event-brands {
   display: grid;
   grid-template-rows: repeat(2, 1fr);
   grid-auto-columns: minmax(9rem, 33%);
   grid-auto-flow: column;
   gap: 2rem 1.5rem;

   overflow-y: auto;

   -ms-overflow-style: none;  /* Internet Explorer 10+ */
   scrollbar-width: none;  /* Firefox */
}

@media only screen and (min-width: 768px) {
   .event-brands {
      grid-auto-columns: minmax(25rem, 33%);
   }
}

.event-brands::-webkit-scrollbar {
   display: none;  /* Safari and Chrome */
}

.event-brand {
   display: flex;

   aspect-ratio: 5 / 2;
   object-fit: contain;
}

.event-brand > img {
   width: 100%;

   object-fit: contain;

}



/* Steps */

.event-steps {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

@media only screen and (min-width: 768px) {
   .event-steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem;
   }
}

.event-step {
   display: flex;

   position: relative;
}

.event-step-number {
   font-size: 2.8rem;
   font-weight: 500;
   line-height: 100%;

   z-index: 2;

   width: 20%;

   aspect-ratio: 1 / 1;

   color: var(--dark-main-color);
   background-color: #FFFFFF;

   border: 0.1rem solid var(--dark-main-color);
   border-radius: 50%;

   flex-shrink: 0;

   display: flex;
   align-items: center;
   justify-content: center;
}

@media only screen and (min-width: 768px) {
   .event-step-number {
      width: 15%;
   }
}

.event-step-body {
   position: absolute;
   left: 10%;

   width: 90%;
   height: 100%;

   padding: 0.7rem 0.5rem 0.7rem calc(10% + 0.5rem);

   border-top-right-radius: 1.5rem;
   border-bottom-right-radius: 1.5rem;

   flex-grow: 1;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   display: flex;
   flex-direction: column;
}

@media only screen and (min-width: 768px) {
   .event-step-body {
      left: 7.5%;

      padding: 1rem 0.7rem 1rem calc(7.5% + 0.7rem);
   }
}

.event-step-title {
   font-weight: 500;
   line-height: 100%;
   
   width: 100%;

   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;

   overflow: hidden;
}

@media only screen and (min-width: 768px) {
   .event-step-title {
      font-size: 1.8rem;
   }
}

.event-step-description {
   font-size: 1.4rem;
   line-height: 100%;

   margin-top: auto;
   margin-bottom: auto;
   
   width: 100%;

   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;

   overflow: hidden;
}

@media only screen and (min-width: 768px) {
   .event-step-description {
      font-size: 1.6rem;
   }
}



/* Faq */

.event-faqs {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.event-faq {
   display: flex;
   flex-direction: column;

   overflow: hidden;

   border: 0.2rem solid var(--dark-main-color);

   border-radius: 0.5rem;
}

@media only screen and (min-width: 768px) {
   .event-faq {
      transition: border-color 0.3s ease;
   }

   .event-faq:has(.event-faq-header:hover, .event-faq-header.open) {
      border-color: var(--main-color);
   }
}

.event-faq-header {
   display: flex;
   align-items: center;
   column-gap: 1rem;

   padding: 0.5rem 1rem;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   border: 0.1rem solid var(--dark-main-color);
}

@media only screen and (min-width: 768px) {
   .event-faq-header {
      cursor: pointer;

      transition: background-color 0.3s ease, border-color 0.3s ease;
   }

   .event-faq-header:is(:hover, .open) {
      background-color: var(--main-color);
      border-color: var(--main-color);
   }
}

.event-faq-header > p {
   flex-grow: 1;
   
   width: 100%;

   display: -webkit-box;
   -webkit-line-clamp: 1;
   -webkit-box-orient: vertical;

   overflow: hidden;
}

.event-faq-header > svg {
   flex-shrink: 0;

   transform: rotateZ(0);

   transition: transform 0.3s ease;
}

.event-faq-header.open > svg {
   transform: rotateZ(180deg);
}

.event-faq-body {
   height: 0;

   transition: height 0.3s ease;
}

.event-faq-body > p {
   padding: 1.5rem 1rem 1rem;

   white-space: pre-line
}