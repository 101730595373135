.product-card-v2 {
   width: 100%;

   display: flex;

   background-color: #FFFFFF;

   border: 0.1rem solid rgba(0, 0, 0, 0.15);
   border-radius: 1rem;

   position: relative;
}

.product-card-v2-imgs {
   width: 25%;

   padding: 1rem 0;

   position: relative;

   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.product-card-v2-img {
   width: 100%;

   display: flex;

   position: relative;

   aspect-ratio: 1 / 1;
}

.product-card-v2-img > img {
   width: 100%;
   
   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-card-v2-recommended {
   position: absolute;
   bottom: -0.1rem;
   left: -0.1rem;

   display: flex;
   align-items: center;

   padding: 0.5rem 1rem;
   
   color: #FFFFFF;
   background-color: var(--main-color);

   border-bottom-left-radius: 1rem;
   border-top-right-radius: 1rem;
}

@keyframes card-ribbon-v2 {
   0% {
      scale: 1;
   }

   50% {
      scale: 1.05;
   }

   100% {
      scale: 1;
   }
}

.product-card-v2-ribbon {
   font-weight: 700;

   animation-name: card-ribbon-v2;
   animation-duration: 1s;
   animation-iteration-count: infinite;
}

.product-card-v2-attributes {
   width: 30%;

   padding: 1rem;

   flex-grow: 1;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   row-gap: 1rem;
}

.product-card-v2-brand {
   width: 65%;

   display: flex;
}

.product-card-v2-brand > img {
   width: 100%;

   aspect-ratio: 4 / 1;
   object-fit: contain;
}

.product-card-v2-attributes-list {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.product-card-v2-btns {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.product-card-v2-btn {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;

   padding: 0.5rem 1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: 2rem;

   color: #FFFFFF;
   background-color: var(--soft-dark-main-color);

   cursor: pointer;

   transition: color 0.2s linear, background-color 0.2s linear;
}

.product-card-v2-btn:hover {
   color: #FFFFFF;

   background-color: var(--main-color);
}

.product-card-v2-data {
   width: 45%;

   max-width: 50rem;

   border-top-right-radius: 1rem;
   border-bottom-right-radius: 1rem;

   padding: 1rem 0 1rem 1rem;

   color: #FFFFFF;
   background-color: var(--dark-main-color);

   display: grid;
   grid-template-columns: repeat(5, 1fr);
   grid-template-areas: "titl titl titl titl titl"
                        "info info info tags tags"
                        "form form form form form";
}

.product-card-v2-name {
   grid-area: titl;

   padding-right: 1rem;
   margin-bottom: 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 1rem;

   text-transform: uppercase;
}

.product-card-v2-name > p {
   font-size: 2rem;
   font-weight: 500;
}

.product-card-v2-name > span {
   font-size: 1.6rem;
   font-weight: 300;
}

.product-card-v2-info {
   grid-area: info;

   padding-right: 1rem;

   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.product-card-v2-prices {
   display: flex;
   flex-direction: column;
   row-gap: 1rem;
}

.product-card-v2-price-group {
   display: flex;
   flex-direction: column;
   row-gap: 0.5rem;
}

.product-card-v2-text {
   font-size: 1.2rem;
   font-weight: 300;
   line-height: 100%;
}

.product-card-v2-text > span {
   color: var(--yellow);
}

.product-card-v2-price {
   font-size: 1.6rem;
   font-weight: 400;
   line-height: 100%;
   letter-spacing: 0.1rem;

   color: var(--light-grey);
}

.product-card-v2-price.offer {
   font-size: 2.8rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;

   color: #FFFFFF;
}

.product-card-v2-price.offer-4x3 {
   font-size: 3.4rem;
   font-weight: 500;
   line-height: 100%;
   letter-spacing: 0.1rem;

   color: var(--light-main-color);
}

.product-card-v2-price > span {
   font-size: 1rem;
}

.product-card-v2-price.offer > span {
   font-size: 1.2rem;
}

.product-card-v2-special-price {
   font-size: 1.4rem;
   font-weight: 300;
   line-height: 100%;
}

.product-card-v2-special-price > span {
   font-weight: 400;

   color: var(--yellow);
}

.product-card-v2-addons {
   align-self: center;
   
   padding: 0.7rem 1.5rem;
   margin-top: auto;

   background-color: var(--soft-dark-main-color);

   font-size: 1.4rem;
   line-height: 100%;
   text-align: center;

   border-radius: 1.5rem;
   
   cursor: pointer;

   transition: background-color 0.1s linear;
}

.product-card-v2-addons:hover {
   background-color: var(--main-color);
}

.product-card-v2-form {
   grid-area: form;

   margin-top: auto;
   padding-right: 1rem;

   display: flex;
   align-items: center;
}

.product-card-v2-input {
   width: 50%;

   padding: 0 5%;

   display: flex;
}

.product-card-v2-input > button {
   width: 2.4rem;
   height: 2.4rem;

   border-radius: 50%;

   display: flex;
   align-items: center;
   justify-content: center;

   cursor: pointer;

   transition: color 0.2s linear, background-color 0.2s linear;
}

.product-card-v2-input > button:hover {
   color: var(--dark-main-color);

   background-color: #FFFFFF;
}

.product-card-v2-input > input {
   width: 50%;

   text-align: center;

   background-color: transparent;
}

.product-card-v2-input > input::-webkit-outer-spin-button,
.product-card-v2-input > input::-webkit-inner-spin-button,
.product-card-v2-input > input[type=number] {
   appearance: textfield;
   -webkit-appearance: none;
   -moz-appearance: textfield;
  
   margin: 0;
}

.product-card-v2-submit {
   width: 50%;

   padding: 0.5rem 1rem;

   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   border-radius: 2rem;

   background-color: var(--main-color);

   cursor: pointer;

   transition: background-color 0.1s linear;
}

.product-card-v2-submit:hover {
   background-color: var(--soft-dark-main-color);
}

.product-card-v2-submit > p {
   flex-grow: 1;

   font-size: 1.4rem;
}

.product-card-v2-tags {
   grid-area: tags;

   display: flex;
   flex-direction: column;
   align-items: flex-end;
   row-gap: 1rem;
}

.product-card-v2-tag {
   min-width: 11rem;

   padding: 0.5rem 0.7rem;

   display: flex;
   align-items: center;
   column-gap: 0.5rem;

   border-top-left-radius: 0.3rem;
   border-bottom-left-radius: 0.3rem;

   background-color: var(--main-color);
}

.product-card-v2-tag > svg {
   font-size: 1.8rem;
   width: 1.8rem;
}

.product-card-v2-tag > p {
   font-size: 1.4rem;
   font-weight: 300;

   flex-grow: 1;
}

.product-card-v2-offer {
   position: absolute;
   top: 1rem;
   left: 1rem;

   width: 7.5%;

   display: flex;
}

.product-card-v2-offer > img {
   width: 100%;

   aspect-ratio: 1 / 1;
   object-fit: contain;
}

.product-card-v2-contact {
   width: 100%;

   display: flex;
   justify-content: center;
}

.product-card-v2-contact-button {
   width: 50%;

   position: relative;

   padding: 0.5rem 1rem;

   display: flex;
   align-items: center;
   justify-content: center;

   border-radius: 2rem;

   background-color: var(--main-color);

   cursor: pointer;

   transition: background-color 0.1s linear;
}

.product-card-v2-contact-button:hover {
   background-color: var(--soft-dark-main-color);
}

.product-card-v2-contact-button > svg {
   position: absolute;
   left: 1rem;
}

.product-card-v2-contact-button > p {
   flex-grow: 1;

   font-size: 1.4rem;
}