.self-management-modal-exit-container {
  position: relative;
  display: flex;
  width: 30%;
  overflow: hidden;
  border-radius: 1rem;
  min-width: 500px;
  border: 2px solid var(--main-color);

  .content{ 
    padding: 2rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 3rem;
      margin-top: 2rem;
    }

    .nv {
      border-top: 1px solid var(--light-grey);
      padding-top: 1rem;
      font-size: 2rem;
    }

    .exit-form,.exit-data, .exit-success {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      justify-content: center;

      border: 1px solid var(--light-grey);
      border-radius: 1rem;
      gap: 3rem;
      padding: 2rem;
      background-color: var(--dark-main-color);
    }

    .exit-data {
      color: #fff;
    }
    
    .exit-success {
      color: #fff;
      
      .exit-check-success {
        height: 50px;
        width: 50px;
        margin: auto;
      }
    }

    .code-input {
      display: flex;
      flex-direction: column;
      color: #fff;

      input{
        border: 1px solid #333;
        border-radius: 1rem;
        padding: .5rem 1rem;
        transition: ease 200ms border;
        max-width: 300px;
      color: #222;
    }

      input:hover, input:focus{
        border: 1px solid var(--main-color);
        border-radius: 1rem;
        padding: .5rem 1rem;
      }
    }

    .btn-submit {
      padding: .8rem 1rem;
      background-color: var(--main-color);
      color: #fff;
      border-radius: 1rem;
      height: fit-content;
      margin-top: auto;
      cursor: pointer;
      transition: ease 200ms background;
      width: 150px;

      &:hover {
        background-color: #fff;
        color: var(--main-color);
      }

      &:disabled {
        cursor: default;
        background-color: var(--light-grey);
      }
    }
  }

  .btn-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    background-color: var(--runflat-tag-color);
    padding: .5rem 1rem;
    border-radius: 0 0 0 1rem;
    color: #FFF
  }
}
